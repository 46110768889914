<script lang="ts" setup>
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

const { getUser } = useUserState()
const user = getUser()
const { getOrganisation } = useOrganisationState()
const organisation = getOrganisation()
const { getCurrentEngagement, getCurrentPhase, engagements } = useEngagementState()
const currentEngagement = getCurrentEngagement()
const currentPhase = getCurrentPhase()
</script>

<template>
  <div>
    <organisation-navbar-dropdown
      v-if="organisation"
      :user
      :organisation
      :current-engagement="currentEngagement"
      :engagements="engagements"
    />

    <div>
      <layout-sidebar
        v-if="organisation"
        :user
        :organisation
        :engagement="currentEngagement"
        :phase="currentPhase"
      />
    </div>

    <div class="main-content d-flex flex-column">
      <div class="flex-grow-1">
        <slot />
      </div>

      <div
        id="footer"
        class="bg-white w-100"
      >
        <ui-container
          fluid
          class="d-flex align-items-center justify-content-end border-bottom border-top py-2 px-4"
        >
          <portal-target id="work-program-introduction-footer" />
        </ui-container>
        <layout-footer fluid />
      </div>
    </div>

    <portal-target id="app-bottom" />
  </div>
</template>

<style lang="scss" scoped>
@supports (not (selector(:has(*)))) {
  .main-content {
    margin-left: 250px;

    .container-fluid {
      padding-left: 2.25rem !important;
      padding-right: 2.25rem !important;
    }
  }
}
</style>
